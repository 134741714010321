import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import emailjs from '@emailjs/browser';
import { Box } from '@mui/material';
import Btn from './../global/Btn'
const Subscribe = () => {

    const { enqueueSnackbar } = useSnackbar();
    const form = useRef()
    const [emailValid, setEmailValid] = useState(true);
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmailValid(isEmailValid(emailValue));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const emailValue = e.target.elements.email.value;

        if (!isEmailValid(emailValue)) {
            enqueueSnackbar('Email Or Phone Is Not Valid', { variant: 'error' });
            return;
        }
        emailjs.sendForm('service_52rwjyj', 'template_74kjvi8', form.current, 'Vo54V2H6iB51cnyGO')
            .then((result) => {
                console.log(result.text);
                enqueueSnackbar('Success Send Data', { variant: 'success' });
                navigate('/')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, (error) => {
                enqueueSnackbar(error, { variant: 'error' });
                console.log(error.text);
            });
        e.target.reset()
    }
    const navigate = useNavigate()
    return (
        <>
            <form ref={form} onSubmit={sendEmail}>
                <input
                    required
                    placeholder='البريد الألكتروني'
                    style={{ backgroundColor: 'rgb(0, 0, 0, 30%)', borderColor: emailValid ? '' : '#F00000' }}
                    id="YourEmail"
                    variant="filled"
                    size="large"
                    name='email'
                    onChange={handleEmailChange}
                />
                {!emailValid && <span style={{ color: '#F00000' }}>Invalid email address</span>}
                <Box pb={2} textAlign={'center'}  >
                    <Btn type='submit' text='ارسال' width={'100%'} />
                </Box>
            </form>
        </>
    )
}

export default Subscribe