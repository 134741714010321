import { Box, CardMedia } from '@mui/material'
import React from 'react'
import logo from './../../assets/Logo/logo.png'
import Social from './Social'
const Logo = () => {
    return (
        <>
            <Box sx={{ position: 'relative', zIndex: 2 }}>
                <CardMedia component={'img'} src={logo} sx={{ width: '75px', my: 2, mx:"auto",   p:1}} />
                {/* <Typography color={'primary.text'} sx={{ my: 2, fontWeight:300 }} >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum incidunt architecto soluta laboriosam, perspiciatis, aspernatur officiis esse.</Typography> */}
                <Social />
            </Box>
        </>
    )
}

export default Logo