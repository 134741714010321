
export const Pages = [
        {
                path: `/`,
                name:`الرئيسية`,
                name_en:`Home`
        },
        {
                path: `services`,
                name:`الخدمات`,
                name_en:`Our Services`
        },
        {
                path: `projects`,
                name:`المشاريع`,
                name_en:`Projects`
        },
        {
                path: `blogs`,
                name:`المقالات`,
                name_en:`Our Blogs`
        },
        {
                path: `about`,
                name: `من نحن`,
                name_en: `About`
        },
        {
                path: `contact`,
                name:`تواصل معنا`,
                name_en:`Contact Us`
        },
]