import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import logo from './../../assets/Logo/logo.png'
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LogoWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& img': {
        animation: `${spin} 2s linear infinite`,
        width: '100px',
        height: '100px'
    }
}));

const Logo = () => {
    return (
        <LogoWrapper>
            <img src={logo} alt="Logo" style={{width:'100%', height:'15rem'}} />
        </LogoWrapper>
    );
};

export default Logo;
