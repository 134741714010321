import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
// import { BasicSelect as TransLang } from "./TransLang"
import './Nav.css'
import i18next from "i18next";
import theme from "../../util/theme";
import { Pages } from "../../constants";
import logo from './../../assets/Logo/logo.png'
import { useTheme } from "@emotion/react";




function NavBar() {
    const themeM = useTheme()
    let lng = i18next.language
    const [drawer, setDrawer] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ backgroundColor: '#fff', py:4 }} >
                <AppBar position="static" elevation={0} className="appNav">
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'space-evenly',
                            }}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    marginInlineStart: 8,
                                    width: isMobile ? '45px':'50px',
                                    height: isMobile ?'45px' :'50px'
                                }}>
                                <img src={logo} alt="logo" className="nav-logo" width={'100%'}/>
                            </Link>
                            {Pages?.map((page, i) => (
                                <Link
                                    key={i}

                                    to={page.path}
                                    style={{ textTransform: 'capitalize', }}
                                    className="link"
                                    onClick={() => scrollToTop()}>
                                    {lng === 'ar' ? page.name : page.name_en}
                                </Link>
                            ))}
                            {/* <TransLang /> */}
                        </Box>
                        <Box sx={{ justifyContent: 'space-between', display: 'flex', gap: { md: '65px', xs: 1 }, width: { md: 'fit-content', xs: '100%' }, alignItems: 'center' }} > 
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: 'center'
                                }}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        marginInlineStart: 8,
                                        width: isMobile ? '45px':'50px',
                                        height: isMobile ?'45px' :'50px'

                                    }}>
                                    <img src={logo} alt="logo" className="nav-logo" style={{ width: '100%', }} ></img>
                                </Link>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '2rem', color: theme.palette.primary.main }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
