import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Pages } from '../../constants'
import { Link } from 'react-router-dom'
import i18next from 'i18next'
import { useTheme } from '@emotion/react'

const Navigation = () => {
    let lng = i18next.language
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <Box sx={{ position: 'relative', zIndex: 2 }}>
            <Typography variant='h6' color={'primary.main'} >روابط مهمة</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Grid container spacing={1}>
                    {Pages?.slice(0,Pages.length -1)?.map((page, i) => (
                        <Grid
                            item
                            xs={6}
                            key={i}>
                            <Link
                                to={page.path}
                                style={{
                                    textTransform: 'capitalize',
                                    textDecoration: 'none',
                                    display: 'block',
                                    width:'fit-content',
                                    color:'#fff',
                                    margin: isMobile &&'auto'
                                }}
                                onClick={() => scrollToTop()}
                            >
                                {lng === 'ar' ? page.name : page.name_en}
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default Navigation