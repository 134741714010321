import React from 'react'
import { Grid, Typography } from '@mui/material'
import Logo from './Logo'
import Navigation from './Navigation'
import Newsletters from './Newsletters'

const Footer = () => {
    return (
        <>
            <footer>
                {/* <div className="overlay"></div> */}
                <Grid container sx={{textAlign:{md:'unset', xs:'center'}}} >
                    <Grid item md={3} xs={12}  sx={{display:'flex', justifyContent:{md:'center',xs:'center'}}} >
                        <Newsletters />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{display:'flex', justifyContent:{md:'center',xs:'center'}}} >
                        <Navigation />
                    </Grid>
                    <Grid item md={3} xs={12} sx={{display:'flex', justifyContent:{md:'center',xs:'center'}}} >
                        <Logo />
                    </Grid>
                </Grid>
                    <Typography color='primary.text' variant={'body2'} fontWeight={400} sx={{position:'relative', zIndex:3, textAlign:'center', mt:2}} >كل الحقوق محفوظة لدى رواكن @2024</Typography>
            </footer>
        </>
    )
}

export default Footer