import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import NavBar from './components/Navbar';
import FixedBtn from './components/global/FixedBtn';
import Footer from './components/Footer/Footer';
import LoadingPage from './pages/LoadingPage';
import NotFound from './pages/NotFound';


// Pages
const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/About"));
const OurServices = lazy(() => import("./pages/OurServices"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Projects = lazy(() => import("./pages/Projects"));
const OurBlogs = lazy(() => import("./pages/OurBlogs"));
const Service = lazy(() => import("./pages/Service"));
const Blog = lazy(() => import("./pages/Blog"));
const Project = lazy(() => import("./pages/Project"));

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_coode: 'gb',
    dir: 'ltr'
  }
]
function App() {

  const [isLoading, setIsLoading] = useState(false)

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();

  useEffect(() => {
    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');

  }, [currentLanguage, t]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      {isLoading ? <LoadingPage /> :
        <>
          <CssBaseline />
          <FixedBtn />
          <NavBar />
          <ScrollToTop />
          <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<OurServices />} />
              <Route path="/service/:id" element={<Service />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/project/:id" element={<Project />} />
              <Route path="/blogs" element={<OurBlogs />} />
              <Route path="/blog/:id" element={<Blog />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </>
      }
    </div>
  );
}

export default App;
