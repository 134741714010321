import { Button } from '@mui/material'
import React from 'react'
import theme from '../../util/theme'
import { useNavigate } from 'react-router-dom';
const Btn = ({ bg, color, text = 'المزيد', path, type = 'button', width= 'fit-content' }) => {
    const navigate = useNavigate()
    return (
        <>
            <Button
                type={type}
                onClick={() => navigate(path)}
                size="large"
                sx={{
                    background: bg ? bg : theme.palette.primary.main,
                    color: color ? color : theme.palette.primary.text, textTransform: 'capitalize', px: 4, py: 1, ":hover": {
                        background: theme.palette.secondary.text,
                        color: theme.palette.secondary.main,
                    },
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    width: width,
                    fontWeight:400
                    // "::before": {
                    //     content: '""',
                    //     position: 'absolute',
                    //     top: '50%',
                    //     right: '0',
                    //     transform: 'translateY(-50%)',
                    //     width: '15px',
                    //     height: '50%',
                    //     backgroundColor: '#000',
                    //     borderTopLeftRadius:'50%',
                    //     borderBottomLeftRadius:'50%',
                    // }
                }} >
                {text}
            </Button>
        </>
    )
}

export default Btn