import { Box, CardMedia } from '@mui/material'
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'; 
import XIcon from '@mui/icons-material/X'; 
import tektok from './../../assets/footer/tictok-svgrepo-com.svg'

const Social = () => {
    return (
        <>
            <Box sx={{display:'flex', alignItems:'center', gap:2, justifyContent:'center'}} >
                <a href="/" target="_blank" rel="noopener noreferrer" style={Link} >
                    <XIcon />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer" style={Link} >
                    <LinkedInIcon />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer" style={Link} >
                    <svg fill="#ffffff" viewBox="0 0 24 24" id="snapchat" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" class="icon line-color"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M21,17a4.33,4.33,0,0,1-4,2s-1,2-5,2-5-2-5-2a4.33,4.33,0,0,1-4-2,4,4,0,0,0,3.35-1.79,3.18,3.18,0,0,0,.57-1.87c0-.11,0-.21,0-.32a7.53,7.53,0,0,1-2.85-.75A1.9,1.9,0,0,1,3,10.53,2,2,0,0,1,4.12,8.71a2,2,0,0,1,1.75,0,2.8,2.8,0,0,0,.67.23,3.46,3.46,0,0,1,0-.45,5.48,5.48,0,0,1,11,0,3.46,3.46,0,0,1,0,.45,2.8,2.8,0,0,0,.67-.23,2,2,0,0,1,1.75,0A2,2,0,0,1,21,10.53a1.9,1.9,0,0,1-1.06,1.74,7.53,7.53,0,0,1-2.85.75c0,.11,0,.21,0,.32a3.18,3.18,0,0,0,.57,1.87A4,4,0,0,0,21,17Z" style={{fill: "none", stroke: "#ffffff", strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2}}></path></g></svg>
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer" style={Link} >
                    <CardMedia
                        alt='test'
                        component={'img'}
                        src={tektok}
                        sx={{ width: '75%' }}/>
                </a>
            </Box>
        </>
    )
}
const Link = {
    color:'#fff',
    backgroundColor:'#c4b498',
    borderRadius:'50%',
    width:'30px',
    height:'30px',
    lineHeight:'30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}
export default Social