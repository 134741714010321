import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { BasicSelect as TransLang } from "./TransLang"
import { Pages } from "../../constants";
import i18next from "i18next";
// import { IconButton } from "@mui/material";
import logo from './../../assets/Logo/logo.png'

export default function NavDrawer({ setDrawer, drawer }) {
    let lng = i18next.language

    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor={'top'}
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    sx={{
                        '.MuiDrawer-paper': {
                            height: '60% !important ',
                            borderBottomRightRadius: '16px',
                            overflowY: 'auto'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: 'center',
                            padding: "40px 20px",
                        }}>
                        <Box
                            sx={{
                                flexGrow: 0,
                                display: { md: "none", xs: "flex" },
                                justifyContent: "center",
                                width: "100%",
                                alignItems: "center",
                            }}>
                            <Link to="/"  style={{width:'75px'}}>
                                <img src={logo} alt="logo" style={{ width: '100%' }} ></img>
                            </Link>
                            {/* <IconButton onClick={() => setDrawer(false)}>
                            </IconButton> */}
                        </Box>
                        {Pages?.map((page, i) => (
                                <Link
                                    key={i}
                                    to={page.path}
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 18,
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                    onClick={() => setDrawer(false)}>
                                    {lng === 'ar' ? page.name : page.name_en}
                                </Link>
                            ))}
                        <TransLang />
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
