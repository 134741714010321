import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#c4b498",
            // main: "#FCCC24",
            text: "#fff",
        },
        secondary: {
            main: "#000",
            text: "#A69F9F"
        },
        background: {
            default: "#fff",
            section: "#f7f7f7",
            card:'#F9F9F9'
        },
    },
    typography: {
        fontWeight: '600',
        h1: {
            fontWeight: 'bold'
        },
        h2: {
            fontWeight: 'bold'
        },
        h3: {
            fontWeight: 'bold'
        },
        h4: {
            fontWeight: 'bold'
        },
        h5: {
            fontWeight: 'bold'
        },
        h6: {
            fontWeight: 'bold'
        },
    },
});

export default theme;
