import { Box, Typography } from '@mui/material'
import React from 'react'
import Subscribe from './Subscribe'

const Newsletters = () => {
    return (
        <>
            <Box sx={{ position: 'relative', zIndex: 2 }}>
                <Typography variant='h6' color={'primary.main'} >تواصل معنا</Typography>
                <Typography color={'primary.text'} >
                   ارسل بريدك الألكتروني لأي استفسار وسنقوم بالرد عليك في اقرب وقت</Typography>
                <Subscribe />
            </Box>
        </>
    )
}

export default Newsletters