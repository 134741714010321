import React, { useState, useEffect } from 'react';
import { Box, keyframes, styled, Typography } from '@mui/material';
import Logo from './../components/global/Logo';
import logo from './../assets/Logo/logo.png'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    animation: `${fadeIn} 2s ease-in-out`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));


const LoadingPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',
                flexDirection: 'column'
            }}
        >
            {isLoading ? (
                <Logo />
            ) : (
                <>
                    <img src={logo} alt="Logo" style={{ height: '10rem' }} />
                    <StyledTypography variant="h5" align="center">
                        The site is making some updates and developments, please come back later.
                    </StyledTypography >
                </>
            )}
        </Box>
    );
};

export default LoadingPage;
