import React from 'react';
import Box from '@mui/material/Box';
// import { useTranslation } from 'react-i18next';
import { Button, CardMedia, Typography } from '@mui/material';
// import i18next from 'i18next';
import translate from './../../assets/translate/OIP.jpg'

export function BasicSelect() {

    // const { i18n } = useTranslation(); 
    // let lng = i18next.language;

    const toggleLanguage = () => {
        // const otherLng = lng === 'en' ? 'ar' : 'en';
        // i18n.changeLanguage(otherLng);
    };


    return (
        <div>
            <Box>
                <Typography onClick={toggleLanguage} sx={{ border:'1px solid #386387', backgroundColor: 'transparent', p:'4px', width:'fit-content', m:'auto', borderRadius:1 }} >
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: 'row-reverse' }} > */}
                        {/* <Typography color={'secondary.main'} fontWeight={'bold'} >{lng === 'en' ? 'العربية' : 'English'}</Typography> */}
                        <CardMedia
                        onClick={toggleLanguage}
                            component={'img'}
                            src={translate}
                            loading='lazy'
                            alt="green iguana"
                            sx={{ width: '30px' }}
                        />
                    {/* </Box>  */}
                    </Typography>
            </Box>
        </div>
    );
}